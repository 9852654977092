<div class="container-fluid py-5">
  <div class="container">
    <div class="col">

      <div class="row pb-lg-4">
        <div class="col">
          <div (window:resize)="onResize($event)" [class]="!mobile ? 'd-flex pb-4 justify-content-left' : 'd-flex pb-4 justify-content-center'">
            <a class="navbar-brand p-0 d-flex align-items-center me-2" href="">
              <img src="/assets/images/logo.png" class="tw-h-[36px]" alt="Car for Ukraine - Logo">
            </a>
            <div class="logo-text">
              <h2 class="mb-0" [style.font-size]="labelSize | translate" [innerText]="label | translate"></h2>
              <h2 class="mb-0">Car for Ukraine</h2>
            </div>
          </div>

          <div class="d-flex d-lg-block text-white justify-content-center">
            <span><i class="bi bi-envelope fs-4 pe-2"></i></span>
            <span class="fs-5">info@car4ukraine.com</span>
          </div>
        </div>

        <div class="col-12 d-lg-none"></div>

        <div class="col pt-5 p-lg-0">
          <div  class="row text-white d-flex justify-content-center fs-5 py-2"
                [innerText]="'modules.footer.follow_us' | translate">
          </div>

          <div class="row">
            <ul class="nav d-flex justify-content-center">
              <ng-container *ngFor="let socialMediaLink of socialMediaLinks">
                <li class="nav-item">
                  <a class="nav-link text-white" [title]="socialMediaLink.title" [href]="socialMediaLink.href" target="_blank">
                    <i class="d-none d-lg-block bi {{ socialMediaLink.bi_icon }} fs-4"></i>
                    <i class="d-lg-none bi {{ socialMediaLink.bi_icon }} fs-1"></i>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div class="col-12 d-lg-none"></div>

        <div class="col pb-5 p-lg-0">
          <div  class="row text-white d-flex justify-content-center fs-5 py-2"
                [innerText]="'modules.footer.other_languages' | translate">
          </div>

          <div class="row">
            <ul class="nav d-flex justify-content-center px-lg-5 px-2">
              <ng-container
                *ngFor="let languageCode of languageCodeList; let i = index">
                <li *ngIf="languageCode != currentLanguageCode" class="text-white list-unstyled px-2">
                  <a
                    [class.active]="languageCode === currentLanguageCode"
                    class="dropdown-item"
                    [href]="getLanguageLinkToCurrentPage(languageCode)"
                    [title]="mapOfLanguageNameAndCode[languageCode]"
                    [innerText]="languageCode | uppercase">
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>

        <div class="col-12 d-lg-none"></div>

        <div class="col d-flex justify-content-center px-0 pb-5 pt-lg-0 pb-lg-4">
          <div class="row d-none d-lg-block my-2 my-lg-4"><app-donate-button></app-donate-button></div>
          <div class="row d-lg-none w-100 btn-container"><app-donate-button class="px-0"></app-donate-button></div>
        </div>
      </div>


      <div class="d-none d-lg-flex justify-content-between border-top border-light border-1 pt-5 px-0 pt-lg-3">

        <div class="px-0 fs-5 text-white">
          <p class="pb-0 mb-0">
            Payment processor partner:
          </p>
          <a  href="https://activeyouth.lt"
              class="text-white text-decoration-underline "
              target="_blank"
              title="Link to activeyouth.lt"
              [innerText]="'AY Institute'">
          </a>
        </div>

        <div class="px-0 fs-5 text-white">
          <p class="pb-0 mb-0">
            Legal representation in Ukraine:
          </p>
          <a  href="https://www.foundationforchange.intellias.com"
              class="text-white text-decoration-underline "
              target="_blank"
              title="Link to foundationforchange.intellias.com"
              [innerText]="'Intellias Foundation for Change'">
          </a>
        </div>

        <!-- <div class="col px-0 fs-5">
          <a  href="https://drive.google.com/file/d/1Rq21Uv7EUbeE21pS9Y_HvqmowQfCOJZb/view"
              class="text-white text-decoration-underline "
              target="_blank"
              [innerText]="'modules.footer.rights_and_policies' | translate">
          </a>
        </div> -->
        <div class="px-0 fs-5 text-white moto"
              [innerText]="'modules.footer.powered_by' | translate">
        </div>
      </div>

      <div class="d-block d-lg-none px-0 mb-4 text-white">
        <span class="d-flex justify-content-center align-items-center pt-3 px-0 fs-5">
          Payment processor partner:
        </span>
        <span class="d-flex justify-content-center align-items-center px-0 fs-5">
          <a  href="https://activeyouth.lt"
              class="text-white text-decoration-underline "
              target="_blank"
              title="Link to activeyouth.lt"
              [innerText]="'AY Institute'">
          </a>
        </span>

        <span class="d-flex justify-content-center align-items-center pt-3 px-0 fs-5">
          Legal representation in Ukraine:
        </span>
        <span class="d-flex justify-content-center align-items-center pb-3 px-0 fs-5">
          <a  href="https://www.foundationforchange.intellias.com"
              class="text-white text-decoration-underline "
              target="_blank"
              title="Link to foundationforchange.intellias.com"
              [innerText]="'Intellias Foundation for Change'">
          </a>
        </span>

        <span class="d-flex justify-content-center align-items-center py-3 px-0 fs-5 text-white"
              [innerText]="'modules.footer.powered_by' | translate">
        </span>
      </div>

    </div>
  </div>
</div>
